/* styles.css */

@media (max-width: 1000px) {
  .right-widget {
    display: none;
  }

  .responsive {
    display: none;
  }
}
