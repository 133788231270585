/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 28, 2019 */

@font-face {
  font-family: 'gilroy-black';
  src: url('gilroy-black-webfont.woff2') format('woff2'),
    url('gilroy-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-bold';
  src: url('gilroy-bold-webfont.woff2') format('woff2'),
    url('gilroy-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-semibold';
  src: url('gilroy-semibold-webfont.woff2') format('woff2'),
    url('gilroy-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-light';
  src: url('gilroy-light-webfont.woff2') format('woff2'),
    url('gilroy-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy-ultralight';
  src: url('gilroy-ultralight-webfont.woff2') format('woff2'),
    url('gilroy-ultralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
